import React from "react"
import SEO from "../components/seo"
import { getUrlParams } from '../helpers'
import ContactForm from "../components/Form"
import Carousel from "nuka-carousel"

const dynamicHeadline = (pageHeader) => {
    if (typeof window !== `undefined`) {
        const headline = getUrlParams(window.location.search).headline
        let header
        if (typeof headline !== `undefined`) {
            let headerArr = headline.toString().split(" ")
            let headerCap = []
            headerArr.forEach((el) => headerCap.push(el.charAt(0).toUpperCase() + el.substring(1)))
            header = headerCap.join(" ")
        } else {
            header = pageHeader
        }
        return header
    }
}


const LandingPage = () => (
    <>
        <SEO title="Ketamine Solutions" />
        <header>
            <div className='hero-section in-pages-bg landing'>
                <div className="container">
                    <h2 className="is-purple landing" style={{ textAlign: 'center' }} >{dynamicHeadline('Clinically Proven IV Treatment for Depression, Anxiety, PTSD and more.')}</h2>

                    <div className="row">
                        <div className='video mt-4'>
                            <iframe style={{borderRadius: '13px'}} title='video' src="https://player.vimeo.com/video/394215544?autoplay=1&loop=1&title=0&byline=0&portrait=0" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                            <div className="bg-white p-3 usp-wrapper landing ml-0 mt-2 with-shadow">
                                <h3 className="is-purple p-1 pl-3 font-weight-bold text-center">What Our Patients Say</h3>
                                <div>
                                    <Carousel
                                        width={'100%'}
                                        renderCenterLeftControls={() => null}
                                        renderCenterRightControls={() => null}
                                        autoplay={true}
                                        pauseOnHover={true}
                                        swiping={true}
                                        autoplayInterval={5000}
                                        wrapAround={true}
                                        className='p-3'
                                    >
                                        <div>
                                            <p className="is-purple pl-3">"The ketamine infusions have been a miracle in my quest to overcome depression.  It has truly brought me back to normal and I can tell that the effects are lasting."</p>
                                            <h3 className="is-purple pl-3 font-weight-bold">Jim C.</h3>
                                        </div>
                                        <div>
                                            <p className="is-purple pl-3">"My treatment consisted of six infusions over a two-week period. I felt slightly better after just one infusion. Since completing the infusions I can honestly say that I am a different person."</p>
                                            <h3 className="is-purple pl-3 font-weight-bold">Dena</h3>
                                        </div>
                                        <div>
                                            <p className="is-purple pl-3">"After reading a lot of information on the results of ketamine treatments I decided to give it a shot. I can’t tell you what a difference it has made in my life!"</p>
                                            <h3 className="is-purple pl-3 font-weight-bold">Jason P.</h3>
                                        </div>
                                        <div>
                                            <p className="is-purple pl-3">"Well, the results have exceeded my expectations - not only has my neuropathy pain been significantly reduced, the symptoms of depression have lifted and I feel terrific!"</p>
                                            <h3 className="is-purple pl-3 font-weight-bold">Steve G</h3>
                                        </div>
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                        <div className="quote-wrapper landing">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </>
)

export default LandingPage
